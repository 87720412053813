
import { defineComponent, onMounted, ref } from "vue"
import FilterBaseV2, { IApiDataEmit, IClientDataEmit } from "@/layout/header/components/FilterBaseV2.vue"
import { useFilterBaseV2Store } from "@/store/FilterBaseV2Store"
import { useAuthStore } from "@/store/AuthStore"
import { useRouter } from "vue-router"
import { useLoaderStore } from "@/store/LoaderStore"

export default defineComponent({
  name: "FilterCompanySession",
  components: {
    FilterBaseV2,
  },
  setup(props, { emit }) {
    /** Variables */
    const isActiveModal = ref(false)
    const isCloseModal = ref(false)
    const filterBaseV2Store = useFilterBaseV2Store()
    const authStore = useAuthStore()
    const router = useRouter()
    const loaderStore = useLoaderStore()

    /** Functions */
    function handleResetFields(emit: IClientDataEmit) { }

    async function handleExecuteSearch(emit: IApiDataEmit) {
      isCloseModal.value = false
      loaderStore.open()
      if(!emit.loading && emit.companyId) {
        await filterBaseV2Store.setCompanyAndStoreInSession(emit.companyId, null)
        isCloseModal.value = true
        if(authStore.isAdmUser)
          return window.location.href = "/companhias"
          // return router.push({ name: "companyList" })
        if(authStore && authStore.getUser && authStore.getUser.role && authStore.getUser.role.level < 600)
          return window.location.href = "/lojas"
          // return router.push({ name: "storeList" })
        else
          return window.location.href = "/lojas"
          // return router.push({ name: "saleList" })
      }
      if(emit.error)
        loaderStore.close()
    }

    /** Life Cycles */
    onMounted(() => isActiveModal.value = true)

    return {
      isActiveModal,
      isCloseModal,
      handleResetFields,
      handleExecuteSearch,
    }
  },
})
